import Layout from "../layout"
import Lenis from '@studio-freight/lenis'
import Menu from "./components/menu"
//
import mitt from 'mitt'
window.LOAD_EVENT = mitt()
// import {
//     DomGl, // Basic threejs setup
//     DomGlPost // threejs setup with postprocessor connected with effect composer
// } from "./dom_gl/index"
class App extends Layout {
    constructor() {
        super()
        this.status = "loading"
        this.menu = new Menu()
        this.setLenis()
        this.setVideos()
    }

    setVideos() {
        document.querySelectorAll("video").forEach(video => this.addVideoObserver(video))
    }

    addVideoObserver(_video) {
        let options = {
            threshold: [0, .5, 1],
            rootMargin: "0px 0px"
        }
        this.observer = new IntersectionObserver(entries => {
            let entry = entries[0]
            if (entry.isIntersecting) return _video.play()
            else _video.pause()
        }, options)
        this.observer.observe(_video)
    }

    setLenis() {
        this.lenis = new Lenis({
            duration: .1,
            lerp: this.lerp,
            orientation: 'vertical',
            gestureOrientation: 'vertical',
            smoothWheel: true,
            smoothTouch: true
        })
        //
        window.RAF.on("animate", (delta) => this.render(delta))
    }

    loaded() {
        if (this.status == "loaded") return false
        this.status = "loaded"
        window.LOAD_EVENT.emit('loaded')
        this.preloader.hide()
    }

    render() {
        const time = new Date().getTime() * .1
        this.lenis.raf(time)
        // STATS.update()
    }

}

const _app = new App()